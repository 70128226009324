import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as _ from 'lodash';

// redux
import { hub_action, project_details_action } from '../../stores/actions'
import { PrimaryImageLayout, ListWrapper, ProposalListing, NewsListing, Loader, ReactangleLoaderView } from '../../components';
import { Animi } from '../../components';
import { Images, StringConstants ,NavigationConstants} from '../../config';
import ReactHtmlParser from 'react-html-parser';
import CommonFooter from '../Legals/CommonFooter';


const Hub = (props) => {
  const dispatch = useDispatch();

  // states
  const [ImageToDisplay, setImageToDisplay] = useState();
  const [ImageZoomAnimation, setImageZoomAnimation] = useState(false);

  const hubData = useSelector(state => state.hub);
  const projectConfigData = useSelector(state => state.projectconfig);
  let proposalImageClass = '';



  useEffect(() => {
    window.scroll(0, 0);
    if (hubData.data === null && hubData.isFetching === false) {
      try {
        dispatch(hub_action());
      } catch (error) {
        console.error(" profile ::  profile() :: Exception ==", error);
      }
    }

  });

  useEffect(() => {
    if (hubData.data !== null && hubData.isSuccess) {
      setImageToDisplay(hubData.data.data.projectConfig.projectFeaturedImage)
      setImageZoomAnimation(hubData.data.data.projectConfig.projectFeaturedImageZoomInHoverEffect)
    }
  }, [hubData]);

  const __renderProjectInfo = () => {

    let projectConfig = hubData.data.data.projectConfig;

    return (
      <Animi.FadeInAnimationDiv>
        <div className="px-4 pt-3 pb-2 project-config">
          <ListWrapper prime={true}>
            <div className="p-4">
              <div className="pb-3">
                <img src={projectConfig.projectSummaryLogo} alt={projectConfig.projectName} title={projectConfig.projectName} style={{ maxWidth: 300 }} />
              </div>
              <p>{ReactHtmlParser(projectConfig.projectDetails)}</p>
            </div>
            <div className="d-sm-flex align-items-center" style={{ borderTop: "1px solid #DFDFDF" }}>
              <div className="px-4 flex-grow-1">
                <p className="pb-0 mb-0 d-flex project-info-text" style={{ fontSize: "1rem", fontWeight: 'bold' }}>
                  <img src={Images.info} alt="" className="pr-3" /> {StringConstants.project_info_text} </p>
              </div>
              <div>
                <Link className="btn btn-primary py-2 px-4 btn-project-details" to={NavigationConstants.archive.url}>{StringConstants.find_out_more}
                  <img src={Images.whitechevron} alt="" className="pl-3" />
                </Link>
              </div>
            </div>
          </ListWrapper>
        </div>
      </Animi.FadeInAnimationDiv>
    )
  }

  const __renderNewsArtical = () => {
    if (hubData.data === null || hubData.data.data.news.length === 0) {
      return
    }

    const data = hubData.data.data.news;

    let news = [];
    _.each(data, (item, id) => {
      news.push(
        <NewsListing key={"news" + id} details={item} type={'small'} backToPageTitle="Homepage" />
      );
    })
    return (
      <div className="px-4 pb-5 pt-0">

        <div className="d-flex">
          <div className="flex-grow-1 have-say-text">
            <h5 className="pb-2">{StringConstants.news_events}</h5>
          </div>
          <div className="sortBy">
            <Link to={NavigationConstants.news_events.url} className="btn btn-link font-weight-bold">{StringConstants.view_all}</Link>
          </div>
        </div>

        <Animi.FadeInAnimationDiv>
          {news}
        </Animi.FadeInAnimationDiv>

      </div>
    )
  }

  const __renderProposal = () => {
    if (hubData.data === null || hubData.data.data.proposals.length === 0) {
      return
    }
    const data = hubData.data.data.proposals;

    let porposal = [];
    // _.each(data, (item, id) => {

    porposal.push(
      <ProposalListing key={"pr_" + 0} details={data[0]} type={'landing'} backToPageTitle="Homepage" />
    );
    // })


    return (
      <div className="px-4 pb-2 pt-0">
        <div className="d-flex">
          <div className="flex-grow-1 have-say-text">
            <h5 className="pb-2 ">{StringConstants.have_your_say}</h5>
          </div>
          <div className="sortBy">
            <Link to={NavigationConstants.share_your_views.url} className="btn btn-link font-weight-bold">{StringConstants.view_all}</Link>
          </div>
        </div>

        <Animi.FadeInAnimationDiv>
          {porposal}
        </Animi.FadeInAnimationDiv>

      </div>
    )
  }



  const __renderProjectInfoLoader = () => {

    return (

      <div className="px-4 pt-3 pb-2 project-config loading-div">
        <ListWrapper prime={true}>
          <div className="padding-first">
            <ReactangleLoaderView imageheight={50} imagewidth={200}></ReactangleLoaderView>
          </div>
          <div style={{ padding: 10 }}>
            <Loader type={'view'} row={4}> </Loader>
          </div>

        </ListWrapper>
      </div>

    )

  }

  const __renderProposalLoader = (val) => {

    return (

      <div className="px-4 pt-3 pb-2 project-config loading-div">
        <div className="main-div">
          <div className="width-30">
            <ReactangleLoaderView imageheight={150} imagewidth={"100%"} ></ReactangleLoaderView>
          </div>
          <div className="width-70">
            <Loader type={'view'} row={1}> </Loader>
            <div style={{ width: '30%' }}>
              <Loader type={'text'} row={1} > </Loader>
            </div>
          </div>
        </div>
        {
          val === '1' ?
            <div className="padding-flex">
              <div style={{ width: '30%', marginRight: 10 }}>
                <Loader type={'text'} row={1}> </Loader>
              </div>
              <div style={{ width: '30%' }}>
                <Loader type={'text'} row={1}> </Loader>
              </div>
            </div> :
            null
        }
      </div>

    )

  }





  return (
    <>
      <div className="fluid-container homebanner">
        <PrimaryImageLayout isAnimated={ImageZoomAnimation} image={ImageToDisplay}>

          {
            !hubData.isSuccess &&
            <>
              {__renderProjectInfoLoader()}
              {__renderProposalLoader('1')}
              {__renderProposalLoader()}
            </>
          }

          {
            hubData.isSuccess &&
            <>
              {__renderProjectInfo()}
              {__renderProposal()}
              {__renderNewsArtical()}
            </>
          }


        </PrimaryImageLayout>
      </div>


      <div className="text-center postion-center  hub-mobile">
        <CommonFooter />
      </div>

    </>
  );
};

export default Hub;