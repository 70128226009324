import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

// redux
import { proposal_detail_by_slug_action, reset_proposal_detail_by_slug_action, survey_start_action, resetSurvey_start_action } from '../../stores/actions'
import { ListWrapper, HeroBannerLayout, ShareVia, Animi } from '../../components'

import { daysToGo } from '../../utils/utility';
import { Images, StringConstants, NavigationConstants } from '../../config'
import CommonFooter from '../Legals/CommonFooter';


const ProposalDetails = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const [baseProposal, setBaseProposal] = useState(props.location.state.proposalDetail);
    const [baseProposal, setBaseProposal] = useState([]);
    const [noQuestions, setNoQuestions] = useState(false);
    const proposalDetailsData = useSelector(state => state.proposal_detail);
    const surveyStartData = useSelector(state => state.survey_start);
    //const backToPageTitle = props.location.state.backToPageTitle || 'Your Say';
    let backToPageTitle;

    useEffect(() => {
        window.scroll(0, 0);
        if (props.location.state !== undefined) {
            setBaseProposal(props.location.state.proposalDetail);
        }

        try {
            let slug = props.location.pathname.replace(NavigationConstants.share_your_views.url + "/", "");
            if (proposalDetailsData.isFetching === false & proposalDetailsData.data === null) {
                dispatch(proposal_detail_by_slug_action(slug))
            }

        }
        catch (e) {
            console.log("proposals error", e)
        }

        // reset state 
        return () => {
            dispatch(reset_proposal_detail_by_slug_action())
        }
    }, []);



    useEffect(() => {

        if (surveyStartData.data !== null && surveyStartData.data.data.proposalQuestions.length > 0) {
            history.push(NavigationConstants.share_your_views.url+"/start/" + baseProposal.proposalSlug, { data: surveyStartData.data.data })
            setNoQuestions(false)
            dispatch(resetSurvey_start_action())
        } else if (surveyStartData.data !== null && surveyStartData.data.data.proposalQuestions.length === 0) {
            setNoQuestions(true)
            dispatch(resetSurvey_start_action())
        }
    }, [surveyStartData])

    useEffect(() => {
        if (proposalDetailsData.isSuccess === true && proposalDetailsData.data !== null) {
            setBaseProposal(proposalDetailsData.data.data.proposal)
        }
    }, [proposalDetailsData])

    const __StartSurvey = () => {

        try {
            if (surveyStartData.isFetching == false && surveyStartData.data == null) {
                dispatch(survey_start_action({ proposalId: baseProposal.proposalId }))
            }

        } catch (e) {
            console.log("proposals error", e)
        }
    }

    if (proposalDetailsData.isFetching) {
        return (
            <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    paddingTop: '50px'
                }}>
                    Loading...
                </div>
            </>
        )
    }

    if (backToPageTitle === undefined) {
        backToPageTitle = 'Your Say';
    } else {
        backToPageTitle = props.location.state.backToPageTitle;
    }



    return (
        <>
            <HeroBannerLayout basepage={backToPageTitle} details={baseProposal} image={baseProposal.proposalImage} imageTitle={baseProposal.proposalTitle} socialShare={false} url={window.location.href}>

                <ListWrapper>
                    <div className="p-4 m-2">
                        <div className="d-flex flex-mob-column">
                            <div className="flex-grow-1">
                                <h3 className="title">{baseProposal.proposalTitle}</h3>
                            </div>
                            <ShareVia url={window.location.href} title={baseProposal.proposalTitle} white={false} />
                        </div>


                        <div className="d-flex flex-mob-column">
                            <div className="">
                                <p className="mb-1 post-details">
                                    <span className="bold pr-5 capitalize">{StringConstants.survey}</span>
                                </p>
                            </div>
                            <div className="flex-grow-1 pl-4 d-flex">

                                {baseProposal.proposalAlwaysLive === 0 ?
                                    <div className="pr-4">
                                        <p className="mb-0 bold d-flex justify-content-center wrap">
                                            <img src={Images.clock} alt="" className="pr-3 " /> {daysToGo(baseProposal.proposalDeadline)} </p>
                                    </div>
                                    : null}
                                <div className="pr-4">
                                    <p className="mb-0 bold d-flex justify-content-center wrap">
                                        <img src={Images.response} alt="" className="pr-3 " />
                                        {baseProposal.proposalTotalResponses} {StringConstants.responses}
                                    </p></div>
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: baseProposal.proposalDesc }} className="mt-4" />
                    </div>
                    <div className="d-flex flex-mob-column addition_cls px-4 mx-2 pb-4 mb-2">
                        <div className="flex-grow-1 d-flex">
                            <div className="pr-5 pt-1 mob-p0">
                                <p className="mb-0 bold d-flex justify-content-center start-now-message"><img src={Images.greensurveyicon} alt="" className="pr-3 " />
                                    {StringConstants.proposal_detail_start_now_message}</p>
                            </div>
                        </div>
                        <div>
                            {
                                surveyStartData.isFetching ?
                                    <button className="btn btn-outline-primary mob-center btn-lg start-now d-flex justify-content-between align-items-center p-3 px-4 greenchevron">
                                        Loading <span className="icon"></span>
                                    </button>
                                    :
                                    <button className="btn btn-outline-primary mob-center btn-lg start-now d-flex justify-content-between align-items-center p-3 px-4 greenchevron"
                                        onClick={() => {
                                            __StartSurvey();
                                        }}
                                    >
                                        {StringConstants.start_now} <span className="icon"></span>
                                    </button>
                            }
                            {noQuestions ? <div className='mob-center  d-flex justify-content-between align-items-center p-3 px-4 greenchevron'>No questions attached to this survey</div> : null}
                        </div>
                    </div>

                </ListWrapper>

                <ListWrapper>
                    <div className="p-4 m-2">
                        <h4 className="title">{StringConstants.proposal_detail_heading}</h4>
                        <p>{StringConstants.proposal_detail_decs}</p>
                    </div>
                    <div className="d-flex flex-mob-column addition_cls" style={{ borderTop: "1px solid #DFDFDF" }}>
                        <div className="flex-grow-1 pl-4 m-2  d-flex">
                            <div className="pr-5 pt-1 mob-p0">
                                <p className="mb-0 bold d-flex justify-content-center">
                                    <img src={Images.info} alt="" className="pr-3 " />
                                    {StringConstants.want_to_hear}
                                </p></div>
                        </div>
                        <div>
                            <button className="btn btn-primary w-100 pt-2 pb-2 pr-4 pl-4"
                                onClick={() => {
                                    history.push(NavigationConstants.archive.url)
                                }}
                            >{StringConstants.find_out_more} <img src={Images.whitechevron} alt="" className="pl-3" />
                            </button>
                        </div>
                    </div>
                </ListWrapper>

            </HeroBannerLayout>

            <div className="text-center postion-center">
                <CommonFooter />
            </div>

        </>

    );
};

export default ProposalDetails;